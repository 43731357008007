@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');

* {
    font-family: 'Outfit', sans-serif;
}

html {
    scroll-behavior: smooth;
}