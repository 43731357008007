.inicioOnda {
    background-image: url('../assets/images/rectangle.png');
    background-repeat: no-repeat;
    /* background-size: contain; */
}

@media screen and (max-width: 1300px) {
    .inicioOnda {

        /* background-size: cover!important; */
    }
}